import React, { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
const lzma = require("lzma/src/lzma_worker.js").LZMA_WORKER
import { useWindowSize } from "../utils/hooks"

import ButtonMotion from "../components/buttonMotion"
import DetailButton from "./detailButton"

import svgRedMana from "../assets/svgRedMana"
import svgGreenMana from "../assets/svgGreenMana"
import svgBluMana from "../assets/svgBluMana"
import svgBlackMana from "../assets/svgBlackMana"
import svgWhiteMana from "../assets/svgWhiteMana"
import svg0 from "../assets/svg0"
import svg1 from "../assets/svg1"
import svg2 from "../assets/svg2"
import svg3 from "../assets/svg3"
import svg4 from "../assets/svg4"
import svg5 from "../assets/svg5"
import svg6 from "../assets/svg6"
import svgInfinity from "../assets/svgInfinity"

/* function decrypt(loadedDeck) {
  if (loadedDeck) {
    const hashed = loadedDeck.hashed
    const parsed = JSON.parse(hashed)
    const decompressed = lzma.decompress(parsed)
    const reparsed = JSON.parse(decompressed)
    return reparsed
  } else {
    return []
  }
} */

const mapTypesFIRST = {
  Creature: true,
  Sorcery: true,
  Instant: true,
  Enchantment: true,
  Artifact: true,
  Planeswalker: true,
}

const mapColorIdentities = {
  WIdentity: svgWhiteMana,
  UIdentity: svgBluMana,
  BIdentity: svgBlackMana,
  RIdentity: svgRedMana,
  GIdentity: svgGreenMana,
}
const mapColorCostToSvg = {
  W: svgWhiteMana,
  U: svgBluMana,
  B: svgBlackMana,
  R: svgRedMana,
  G: svgGreenMana,
}

const mapProducedMana = {
  W: "Plains",
  U: "Island",
  B: "Swamp",
  R: "Mountain",
  G: "Forest",
  C: "Colorless",
}

const DeckDetailsV = props => {
  const [windowSize, setWindowSize] = useWindowSize()
  const [tableHeight, setTableHeight] = useState(1400)
  const deckCards = []
  const sideboardCards = []
  const mapMana = {
    W: {
      quantity: 0,
      unicoIds: [],
    },
    U: {
      quantity: 0,
      unicoIds: [],
    },
    B: {
      quantity: 0,
      unicoIds: [],
    },
    R: {
      quantity: 0,
      unicoIds: [],
    },
    G: {
      quantity: 0,
      unicoIds: [],
    },
    C: {
      quantity: 0,
      unicoIds: [],
    },
  }
  let totalBasicLand = 0
  let cardsNotDraggedCounter = 1

  const chosenCards = props.decryptedHashed

  for (let i = 0; i < chosenCards.length; i++) {
    let curCard = chosenCards[i]
    if (curCard.type_line.includes("Basic")) {
      deckCards.push(curCard)
      curCard.place = "deck"
      totalBasicLand = totalBasicLand + 1
      mapMana[curCard.produced_mana[0]]["quantity"] =
        mapMana[curCard.produced_mana[0]]["quantity"] + 1
      mapMana[curCard.produced_mana[0]]["unicoIds"].push(curCard.unico)
      continue
    }
    if (!curCard.hasBeenDragged) {
      curCard.position = {
        x: windowSize.width - 200,
        y: cardsNotDraggedCounter++ * 25,
      }
      /*       curCard.temporary = true */
    }
    if (
      curCard.hasBeenDragged &&
      curCard.position.y <= tableHeight * 0.55 - 125
    ) {
      // CANCEL PROPERTY, measure based of windowWidth
      deckCards.push(curCard)
      curCard.place = "deck"
    } else if (
      curCard.hasBeenDragged &&
      curCard.position.y > tableHeight * 0.55 - 125 &&
      curCard.position.y < tableHeight * 0.8 - 125
    ) {
      curCard.place = "temporary"
    } else if (
      curCard.hasBeenDragged &&
      curCard.position.y >= tableHeight * 0.8 - 125
    ) {
      sideboardCards.push(curCard)
      curCard.place = "sideboard"
    }
  }
  const regExpr = /\b[^\d\W]+\b/g
  let totalManaCost = 0
  let totalNonBasicLand = 0
  const mapManaCost = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    "7+": 0,
  }
  const mapDeckCardsTypeLine = {}
  const mapDeckCardsProducedMana = {}
  const mapDeckCardsColorIdentity = {}
  const mapDeckCardsColorCost = { W: 0, U: 0, B: 0, R: 0, G: 0 }
  let nOfCardsBothLandAndNonland = 0
  for (let i = 0; i < deckCards.length; i++) {
    let curCard = deckCards[i]
    const allTypes = curCard.type_line?.match(regExpr)
    const typesArr = [...new Set(allTypes)]
    if (curCard.colors) {
      for (let i = 0; i < curCard.colors.length; i++) {
        let curLetter = curCard.colors[i]
        mapDeckCardsColorCost[curLetter] = mapDeckCardsColorCost[curLetter] + 1
      }
    } else if (curCard.card_faces?.length > 0 && curCard.card_faces[0].colors) {
      for (let i = 0; i < curCard.card_faces[0].colors.length; i++) {
        let curLetter = curCard.card_faces[0].colors[i]
        mapDeckCardsColorCost[curLetter] = mapDeckCardsColorCost[curLetter] + 1
      }
    }

    typesArr.forEach(typeString => {
      if (!mapDeckCardsTypeLine[typeString])
        mapDeckCardsTypeLine[typeString] = 0
      mapDeckCardsTypeLine[typeString] = mapDeckCardsTypeLine[typeString] + 1
    })
    totalManaCost = totalManaCost + curCard.cmc
    if (typesArr.includes("Land") && !typesArr.includes("Basic")) {
      totalNonBasicLand = totalNonBasicLand + 1
      if (curCard.cmc > 0) {
        nOfCardsBothLandAndNonland = nOfCardsBothLandAndNonland + 1
      }
      // IF LAND, INCREMENT MANA PRODUCED
      if (curCard.produced_mana) {
        for (let j = 0; j < curCard.produced_mana.length; j++) {
          //     produced_mana: ["B", "G", "R", "U", "W"],
          let manaLetter = curCard.produced_mana[j]
          if (!mapDeckCardsProducedMana[manaLetter])
            mapDeckCardsProducedMana[manaLetter] = 0
          mapDeckCardsProducedMana[manaLetter] =
            mapDeckCardsProducedMana[manaLetter] + 1
        }
      }
    } else if (!typesArr.includes("Basic")) {
      // IF !LAND, INCREMENT COLOR IDENTITY
      if (curCard.cmc < 7) {
        mapManaCost[curCard.cmc] = mapManaCost[curCard.cmc] + 1
      } else {
        mapManaCost["7+"] = mapManaCost["7+"] + 1
      }
      for (let z = 0; z < curCard.color_identity?.length; z++) {
        let identityLetter = curCard.color_identity[z]
        identityLetter = identityLetter + "Identity"
        if (!mapDeckCardsColorIdentity[identityLetter])
          mapDeckCardsColorIdentity[identityLetter] = 0
        mapDeckCardsColorIdentity[identityLetter] =
          mapDeckCardsColorIdentity[identityLetter] + 1
      }
    }
  }
  let averageManaCost =
    totalManaCost /
    (deckCards.length -
      totalNonBasicLand -
      totalBasicLand +
      nOfCardsBothLandAndNonland)
  for (const [key, value] of Object.entries(mapMana)) {
    if (mapMana[key]["quantity"] > 0) {
      if (!mapDeckCardsProducedMana[key]) mapDeckCardsProducedMana[key] = 0
      mapDeckCardsProducedMana[key] =
        mapDeckCardsProducedMana[key] + mapMana[key]["quantity"]
    }
  }

  // DeckDetails da qui in poi
  const [isColorsOpen, switchIsColors] = useState(true)
  const [isTypesOpen, switchIsTypes] = useState(true)
  const [isLandOpen, switchIsLand] = useState(true)
  const [isCurveOpen, switchIsCurve] = useState(true)

  // TYPES
  const deckCardsTypeLineArrayFIRST = []
  const deckCardsTypeLineArraySECOND = []
  for (const [chiave, value] of Object.entries(mapDeckCardsTypeLine)) {
    if (mapTypesFIRST[chiave]) {
      deckCardsTypeLineArrayFIRST.push(
        <DetailButton key={chiave} chiave={chiave} quantity={value} />
      )
    } else {
      deckCardsTypeLineArraySECOND.push(
        <DetailButton key={chiave} chiave={chiave} quantity={value} />
      )
    }
  }
  deckCardsTypeLineArrayFIRST.sort((a, b) => (a.key > b.key ? 1 : -1))
  deckCardsTypeLineArraySECOND.sort((a, b) => (a.key > b.key ? 1 : -1))
  const renderTypes = [
    ...deckCardsTypeLineArrayFIRST,
    ...deckCardsTypeLineArraySECOND,
  ]

  // COLOR COST
  const renderColorCost = []
  const colorCostOrder = ["W", "U", "B", "R", "G"]
  for (const [chiave, valore] of Object.entries(mapDeckCardsColorCost)) {
    renderColorCost.push(
      <DetailButton
        key={chiave}
        chiave={mapColorCostToSvg[chiave]}
        quantity={valore}
      />
    )
  }
  renderColorCost.sort((a, b) =>
    colorCostOrder.indexOf(a.key) > colorCostOrder.indexOf(b.key) ? 1 : -1
  )

  // PRODUCED MANA
  const renderProducedMana = []
  const landOrder = ["W", "U", "B", "R", "G"]
  for (const [chiave, value] of Object.entries(mapDeckCardsProducedMana)) {
    renderProducedMana.push(
      <DetailButton
        key={chiave}
        chiave={mapProducedMana[chiave]}
        quantity={value}
      />
    )
  }
  renderProducedMana
    .sort((a, b) =>
      landOrder.indexOf(a.key) > landOrder.indexOf(b.key) ? 1 : -1
    )
    .push(
      <DetailButton
        key={"TotalLand"}
        chiave={"Lands"}
        quantity={totalBasicLand + totalNonBasicLand}
      />
    )

  return (
    /*     <DeckDetailsWrapper
      variants={variants}
      initial="closed"
      animate={showDeckDetails ? "open" : "closed"}
      transition={{ damping: 300 }}
    >
      <ButtonCloseDetails>
        <ButtonMotion
          handleClick={() => setShowDeckDetails(prev => !prev)}
          text="Table"
        />
      </ButtonCloseDetails> */
    <>
      <BoxTypes>
        <ButtonMotion
          handleClick={() => switchIsTypes(prev => !prev)}
          text="Type"
        />
        <BoxTypes_Accordion
          variants={listVariants}
          style={{ overflow: "hidden" }}
          initial="open"
          animate={isTypesOpen ? "open" : "closed"}
          transition={{ damping: 300 }}
        >
          {renderTypes}
        </BoxTypes_Accordion>
      </BoxTypes>
      <BoxTypes>
        <ButtonMotion
          handleClick={() => switchIsColors(prev => !prev)}
          text="Color"
        />
        <BoxTypes_Accordion
          variants={listVariants}
          style={{ overflow: "hidden" }}
          initial="open"
          animate={isColorsOpen ? "open" : "closed"}
          transition={{ damping: 300 }}
        >
          {renderColorCost}
        </BoxTypes_Accordion>
      </BoxTypes>
      <BoxTypes>
        <ButtonMotion
          handleClick={() => switchIsLand(prev => !prev)}
          text="Produced Mana"
        />
        <BoxTypes_Accordion
          variants={listVariants}
          style={{ overflow: "hidden" }}
          initial="open"
          animate={isLandOpen ? "open" : "closed"}
          transition={{ damping: 300 }}
        >
          {renderProducedMana}
        </BoxTypes_Accordion>
      </BoxTypes>
      <BoxCurve>
        <ButtonMotion
          handleClick={() => switchIsCurve(prev => !prev)}
          text="Mana Curve"
        />
        <BoxTypes_Accordion
          variants={listVariants}
          style={{ overflow: "hidden" }}
          initial="open"
          animate={isCurveOpen ? "open" : "closed"}
          transition={{ damping: 300 }}
        >
          <FlexCol>
            <Graph>
              {Array.from(Array(mapManaCost["1"]), (_, i) => (
                <Unity key={i} />
              ))}
            </Graph>
            <div>{svg1}</div>
            <TextQuantity>{mapManaCost["1"]}</TextQuantity>
          </FlexCol>
          <FlexCol>
            <Graph>
              {Array.from(Array(mapManaCost["2"]), (_, i) => (
                <Unity key={i} />
              ))}
            </Graph>
            <div>{svg2}</div>
            <TextQuantity>{mapManaCost["2"]}</TextQuantity>
          </FlexCol>
          <FlexCol>
            <Graph>
              {Array.from(Array(mapManaCost["3"]), (_, i) => (
                <Unity key={i} />
              ))}
            </Graph>
            <div>{svg3}</div>
            <TextQuantity>{mapManaCost["3"]}</TextQuantity>
          </FlexCol>
          <FlexCol>
            <Graph>
              {Array.from(Array(mapManaCost["4"]), (_, i) => (
                <Unity key={i} />
              ))}
            </Graph>
            <div>{svg4}</div>
            <TextQuantity>{mapManaCost["4"]}</TextQuantity>
          </FlexCol>
          <FlexCol>
            <Graph>
              {Array.from(Array(mapManaCost["5"]), (_, i) => (
                <Unity key={i} />
              ))}
            </Graph>
            <div>{svg5}</div>
            <TextQuantity>{mapManaCost["5"]}</TextQuantity>
          </FlexCol>
          <FlexCol>
            <Graph>
              {Array.from(Array(mapManaCost["6"]), (_, i) => (
                <Unity key={i} />
              ))}
            </Graph>
            <div>{svg6}</div>
            <TextQuantity>{mapManaCost["6"]}</TextQuantity>
          </FlexCol>
          <FlexCol>
            <Graph>
              {Array.from(Array(mapManaCost["7+"]), (_, i) => (
                <Unity key={i} />
              ))}
            </Graph>
            <div>{svgInfinity}</div>
            <TextQuantity>{mapManaCost["7+"]}</TextQuantity>
          </FlexCol>

          <Average>Average: {averageManaCost.toFixed(2)}</Average>
        </BoxTypes_Accordion>
      </BoxCurve>
    </>
    /*     </DeckDetailsWrapper> */
  )
}

const Average = styled.div`
  flex-basis: 100%;
  margin-top: 1.2rem;
  color: #cccccc;
`
const TextQuantity = styled.div`
  margin: 0.1rem;
  color: #cccccc;
`
const BoxCurve = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 2rem;
`

const FlexCol = styled.div`
  margin-right: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

const Graph = styled.div`
  /*  height: 200px; */
  width: 30px;
  background-color: pink;
  margin: 0.8rem 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const Unity = styled.div`
  height: 30px;
  width: 30px;
  background-color: #ff974c;
`

const DeckDetailsWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: radial-gradient(#555, #111);
  padding: 1rem;
  z-index: 1000000;
  color: #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const BoxTypes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 2rem;
`
const BoxTypes_Accordion = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
`

const ButtonCloseDetails = styled.div`
  position: absolute;
  top: 5px;
  left: 0;
`
const variants = {
  open: { x: 0 },
  closed: {
    x: "-100%",
    transition: {
      delay: 0,
      x: { type: "tween" },
    },
  },
}

const listVariants = {
  open: { opacity: 1, height: "auto" },
  closed: { opacity: 0, height: 0 },
}

export default DeckDetailsV
