import React, { useState, useEffect, useContext } from "react"
import { navigate, Link } from "gatsby"
import styled from "styled-components"
import { useQueryCache, useQuery, useMutation } from "react-query"
const lzma = require("lzma/src/lzma_worker.js").LZMA_WORKER
import { useToasts } from "react-toast-notifications"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { motion, AnimatePresence } from "framer-motion"

import SessionContext from "./../context/session"
import { faunaQueries } from "../fauna/query-manager"
import { safeVerifyError } from "../fauna/helpers/errors"

import Profile from "../components/profile"
import EasyV from "./../components/EasyV"
import DeckDetailsV from "./../components/deckDetailsV"
import {
  logoutBecauseUnauthorized,
  removeDeckFromCache,
} from "../fauna/helpers/util"
import svgarnold from "../assets/svgarnold"
import svgdelete from "../assets/svgdelete"
import Spinner from "../components/Spinner"
import SEO from "./../components/seo"
import HelperView from "../components/HelperView"
import {
  add,
  remove,
  downloadTxtFile,
  getArenaExport,
  getMtgoExport,
} from "../utils/helpers"

/* import svgheart from "../assets/svgheart" */
import svgupvote from "../assets/svgupvote"
import svgarena from "../assets/svgarena"
import svgmtgo from "../assets/svgmtgo"

function decrypt(loadedDeck) {
  if (loadedDeck && loadedDeck.hashed) {
    const hashed = loadedDeck.hashed
    const parsed = JSON.parse(hashed)
    const decompressed = lzma.decompress(parsed)
    const reparsed = JSON.parse(decompressed)
    return reparsed
  } else {
    return []
  }
}

const Deck = props => {
  /*   console.log("Deck.js mounting") */

  const { addToast } = useToasts()
  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state

  const [showHelperView, setShowHelperView] = useState(false)
  const [notifications, setNotifications] = useState([])

  const queryCache = useQueryCache()
  const queryInfo = useDeckByRef(
    queryCache,
    props.params["*"],
    addToast,
    logoutBecauseUnauthorized,
    sessionContext
  )
  /*   console.log("Deck/queryInfo -> queryInfo", queryInfo) */

  let decryptedHashed = null
  let toPrint = null
  if (queryInfo.data) {
    decryptedHashed = decrypt(queryInfo.data)
    toPrint = getArenaExport(decryptedHashed)
  }
  /*   const queryInfo.data___LOGGED = {
    alias: "raro",
    author: { value: {} },
    cards: "SUPERLONGSTRING",
    color: "UR",
    created: { value: "2021-02-05T22:48:37.527246Z" },
    curUserIsAuthor: false,
    deckRef: { value: {} },
    format: "StandardBO3",
    hashed: "[93,0,0,-128,0,86,-112,0,0,0,0,0,0,0,45,-98,-64,71",
    isFavorite: false,
    isPublic: true,
    likes: 0,
    name: "Sbobba",
  } */

  const [deleteDeck, deleteDeckInfo] = useDeleteDeck(
    queryCache,
    addToast,
    logoutBecauseUnauthorized,
    sessionContext
  )
  /*   console.log("deleteDeckInfo", deleteDeckInfo) */
  const [likeDeck, likeDeckInfo] = useLikeDeck(
    queryCache,
    addToast,
    logoutBecauseUnauthorized,
    sessionContext
  )
  /*   console.log("likeDeckInfo", likeDeckInfo) */

  useEffect(() => {
    if (!user && faunaQueries.getUser()) {
      sessionContext.dispatch({ type: "login", data: faunaQueries.getUser() })
    }
    return function cleanup() {
      /*       console.log("Deck.js/unmounting") */
    }
  }, [props.params["*"], user, sessionContext])

  return (
    <>
      <SEO
        slug="/mtg-deck-builder/"
        title="Build upon this deck with our MTG Deck Builder"
        description="Build upon this MTG deck. Take inspiration and brew something much more powerful with our MTG Deck Builder."
      />
      <Profile />
      <BackgroundRadial>
        {queryInfo.isLoading ? (
          <Spinner />
        ) : queryInfo.isSuccess && queryInfo.data?.myError ? (
          <h1>ERROR B001</h1>
        ) : queryInfo.isSuccess && !queryInfo.data ? (
          <h1>ERROR B002</h1>
        ) : queryInfo.isSuccess && queryInfo.data ? (
          <PageLayout>
            <Left>
              <MyText
                style={{
                  "--font-size": "1.6rem",
                  "--margin-bottom": "0.3rem",
                  "--text-shadow": "0 0 5px rgba(0, 0, 0, 0.1)",
                  "--text-shadow-hover": "0 0 5px rgba(0, 0, 0, 0.1)",
                }}
              >
                created by&nbsp;
              </MyText>
              <Link to={`/user/${queryInfo.data.alias}`}>
                <MyText
                  style={{
                    "--font-size": "3.2rem",
                    "--margin-bottom": "0.5rem",
                    "--text-shadow": "0 1px 12px #ff974c",
                    "--text-shadow-hover": "0 1px 12px  #2DE2E6",
                  }}
                >
                  {queryInfo.data.alias}
                </MyText>
              </Link>
              <Row_Icons>
                {user?.tier === "rare" || user?.tier === "silver" ? (
                  <ButtonFollow
                    style={{
                      "--stroke": "#ff974c",
                      "--width": "24px",
                      "--height": "24px",
                      "--fill": queryInfo.data?.isFavorite // isAlreadyLiked?
                        ? "#ff974c"
                        : "none",
                    }}
                    as="button"
                    onClick={e => likeDeck(props.params["*"])}
                  >
                    {svgupvote}
                  </ButtonFollow>
                ) : null}
                {user?.tier === "rare" || user?.tier === "silver" ? (
                  <ButtonFollow
                    style={{
                      "--width": "32px",
                      "--height": "32px",
                      "--fill": "#ff974c",
                    }}
                    as="button"
                    onClick={e =>
                      navigate(`/mtg-deck-builder/${props.params["*"]}`)
                    }
                  >
                    {svgarnold}
                  </ButtonFollow>
                ) : null}
                {user?.alias === queryInfo.data.alias && (
                  <ButtonFollow
                    style={{
                      /*                       "--width": "24px",
                      "--height": "24px", */
                      "--fill": "#ff974c",
                    }}
                    as="button"
                    onClick={e => deleteDeck(props.params["*"])}
                  >
                    {svgdelete}
                  </ButtonFollow>
                )}
                <CopyToClipboard text={toPrint}>
                  <ButtonExport
                    onClick={() => {
                      setNotifications(add(notifications))
                      setTimeout(() => {
                        setNotifications(
                          remove(
                            notifications,
                            notifications[notifications.length]
                          )
                        )
                      }, 1000)
                    }}
                  >
                    {svgarena}
                    <WrapperNotifications>
                      <AnimatePresence initial={false}>
                        {notifications.map(idNotification => (
                          <Notification
                            onClick={e => e.stopPropagation()}
                            key={idNotification}
                            positionTransition
                            initial={{ opacity: 0, y: 50, scale: 0.3 }}
                            animate={{ opacity: 1, y: 0, scale: 1 }}
                            exit={{
                              opacity: 0,
                              scale: 0.5,
                              transition: { duration: 0.2 },
                            }}
                          >
                            {/*       <CloseButton
                    close={e => {
                      e.stopPropagation()
                      setNotifications(remove(notifications, idNotification))
                    }}
                  /> */}
                            <NotificationText
                              onClick={() =>
                                setNotifications(
                                  remove(notifications, idNotification)
                                )
                              }
                            >
                              Copied to clipboard
                              <ButtonDelete>X</ButtonDelete>
                            </NotificationText>
                          </Notification>
                        ))}
                      </AnimatePresence>
                    </WrapperNotifications>
                  </ButtonExport>
                </CopyToClipboard>
                <ButtonExport
                  onClick={() => {
                    let deckList = getMtgoExport(decryptedHashed)
                    downloadTxtFile(deckList)
                  }}
                >
                  {svgmtgo}
                </ButtonExport>
              </Row_Icons>
              <DeckDetailsV decryptedHashed={decryptedHashed} />
            </Left>
            <Right>
              <EasyV decryptedHashed={decryptedHashed} />
            </Right>
          </PageLayout>
        ) : queryInfo.isIdle ? ( // quando si va direttamente al deck, prima di caricare si ha questo. S: fare Loading.
          <Spinner />
        ) : queryInfo.isError ? (
          <h1>ERROR B004</h1>
        ) : (
          <h1>ERROR B005</h1>
        )}
        <HelperToggle onClick={() => setShowHelperView(true)}>i</HelperToggle>

        <HelperView
          showHelperView={showHelperView}
          setShowHelperView={setShowHelperView}
          helperPage="deck"
        />
      </BackgroundRadial>
    </>
  )
}

export default Deck

function useDeckByRef(
  queryCache,
  deckRefUDF,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  return useQuery(
    ["QM_get_deck_where_ref", deckRefUDF],
    (prefix, key, after = null) =>
      fetch_get_deck_where_ref(
        queryCache,
        deckRefUDF,
        addToast,
        logoutBecauseUnauthorized,
        sessionContext
      ),
    {
      staleTime: 0,
      enabled: deckRefUDF?.length > 0,
      initialData: () => queryCache.getQueryData(["initial_deck", deckRefUDF]),
      initialStale: true,

      onError: (error, values, rollback) => {
        /*         console.log("useDeckByRef/onError: ", error) */
        if (
          error.error ||
          error.description === "Unauthorized" ||
          error.message === "permission denied"
        ) {
          logoutBecauseUnauthorized(sessionContext, addToast, error.error)
        } else {
          const errorDescription = safeVerifyError(error, [
            "requestResult",
            "responseContent",
            "errors",
            0,
            "cause",
            0,
            "description",
          ])
          addToast(errorDescription, { appearance: "error" })
        }
      },
    }
  )
}

async function fetch_get_deck_where_ref(
  queryCache,
  deckRefUDF,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  // deck {}
  /// Abort("This deck is private")
  /// Abort("1145The deck you're trying to access doesn't exist")
  try {
    const response = await faunaQueries.QM_get_deck_where_ref(deckRefUDF)
    /*     console.log("fetch_get_deck_where_ref/response: ", response) */
    return response
  } catch (err) {
    /*     console.log("fetch_get_deck_where_ref -> err: ", err) */
    /* logoutBecauseUnauthorized(sessionContext, addToast, err) */
    throw err
  }
}

function useDeleteDeck(
  queryCache,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  return useMutation(
    values =>
      fetchDeleteDeck(
        queryCache,
        values,
        addToast,
        logoutBecauseUnauthorized,
        sessionContext
      ),
    {
      onMutate: async values => {
        await queryCache.cancelQueries(["QM_get_deck_where_ref", values])
        const inCacheDeck = queryCache.getQueryData([
          "QM_get_deck_where_ref",
          values,
        ]) // ?e' questo superfluo, dato che il secondo params di setQueryData e', se vogliamo, una funzione con il vecchio state come argomento gia presente? (tipo setState) - No, facciamo cosi perche' poi ci serve per il rollback di dopo
        await navigate(-1)
        // ho inserito navigate qui, e sopra questa linea, cosi nmon triggera di nuovo get_deck_where_ref
        queryCache.setQueryData(["QM_get_deck_where_ref", values], {
          myError: "This deck has been deleted",
        })
        return () =>
          queryCache.setQueryData(
            ["QM_get_deck_where_ref", values],
            inCacheDeck
          )
      },
      onError: (error, values, rollback) => {
        /*         console.log("useDeleteDeck/onError: ", error) */
        if (
          error.error ||
          error.description === "Unauthorized" ||
          error.message === "permission denied"
        ) {
          logoutBecauseUnauthorized(sessionContext, addToast, error.error)
        } else {
          const errorDescription = safeVerifyError(error, [
            "requestResult",
            "responseContent",
            "errors",
            0,
            "cause",
            0,
            "description",
          ])
          addToast(errorDescription, { appearance: "error" })
        }
        if (rollback) {
          rollback()
          navigate(1)
        }
        // e fai anche logout (already handled in the catch clause in fetchDeleteDeck)
      },
      onSuccess: (data, values) => {
        /*         console.log("useDeleteDeck/onSuccess: ", data) */
        /* queryCache.setQueryData(["QM_get_deck_where_ref", values], { // Tanner replaces it again based on the coming 'data'. I don't.
            myError: "This deck has been deleted",
          }) */
        removeDeckFromCache(queryCache, "QM_get_decks_all", values)
        removeDeckFromCache(
          queryCache,
          `QM_get_decks_where_alias-${sessionContext.state.user.alias}`,
          values
        )
        removeDeckFromCache(queryCache, "QM_get_decks_mine", values)
        removeDeckFromCache(queryCache, "QM_get_decks_favorite", values)
      },
      onSettled: (data, error, values, rollback) => {
        queryCache.invalidateQueries(["QM_get_deck_where_ref", values])
        queryCache.invalidateQueries("QM_get_decks_all")
        queryCache.invalidateQueries(
          `QM_get_decks_where_alias-${sessionContext.state.user.alias}`
        )
        // queryCache.invalidateQueries("QM_get_decks_friends") no perche` questo deck deletato non poteva mai essere dei friends
        queryCache.invalidateQueries("QM_get_decks_mine")
        queryCache.invalidateQueries("QM_get_decks_favorite")
        // queryCache.invalidateQueries("QM_get_friends") // nessun motivo
        // queryCache.invalidateQueries("QM_get_user_info") // nessun motivo
      },
    }
  )
}

async function fetchDeleteDeck(
  queryCache,
  numRefDeckUDF,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  try {
    const response = await faunaQueries.QM_delete_deck(numRefDeckUDF)
    /*     console.log("fetchDeleteDeck/response: ", response) */
    return true
  } catch (err) {
    /*     console.log("fetchDeleteDeck/err: ", err) */
    throw err
  }
}

function useLikeDeck(
  queryCache,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  return useMutation(
    values =>
      fetchLikeDeck(
        queryCache,
        values,
        addToast,
        logoutBecauseUnauthorized,
        sessionContext
      ),
    {
      onMutate: values => {
        queryCache.cancelQueries(["QM_get_deck_where_ref", values])
        const cached_deck = queryCache.getQueryData([
          "QM_get_deck_where_ref",
          values,
        ])
        queryCache.setQueryData(["QM_get_deck_where_ref", values], {
          ...cached_deck,
          isFavorite: !cached_deck["isFavorite"],
        })
        return () =>
          queryCache.setQueryData(
            ["QM_get_deck_where_ref", values],
            cached_deck
          )
      },
      onError: (error, values, rollback) => {
        /*         console.log("useLikeDeck/onError: ", error) */
        if (
          error.error ||
          error.description === "Unauthorized" ||
          error.message === "permission denied"
        ) {
          logoutBecauseUnauthorized(sessionContext, addToast, error.error)
        } else {
          const errorDescription = safeVerifyError(error, [
            "requestResult",
            "responseContent",
            "errors",
            0,
            "cause",
            0,
            "description",
          ])
          addToast(errorDescription, { appearance: "error" })
        }
        if (rollback) {
          rollback()
        }
      },
      onSuccess: (data, values) => {
        const cached_deck = queryCache.getQueryData([
          "QM_get_deck_where_ref",
          values,
        ])
        if (data === "unliked") {
          queryCache.setQueryData(["QM_get_deck_where_ref", values], {
            ...cached_deck,
            isFavorite: false,
            likes: cached_deck["likes"] - 1,
          })
        } else if (data === "liked") {
          queryCache.setQueryData(["QM_get_deck_where_ref", values], {
            ...cached_deck,
            isFavorite: true,
            likes: cached_deck["likes"] + 1,
          })
        }
      },
      onSettled: (data, error, values, rollback) => {
        queryCache.invalidateQueries(["QM_get_deck_where_ref", values]) // questo richiede immediatamente il deck. Non e` essenziale xk abbiamo modificato la cache.
        queryCache.invalidateQueries(["QM_get_decks_favorite"])
      },
    }
  )
}

async function fetchLikeDeck(
  queryCache,
  numRefDeckUDF,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  // 'liked' || 'unliked' || Abort("The deck you're trying to access doesn't exist")
  try {
    const response = await faunaQueries.QM_like_deck(numRefDeckUDF)
    /*     console.log("fetchLikeDeck -> response", response) */
    return response
  } catch (err) {
    /*     console.log("fetchLikeDeck -> err: ", err) */
    /* logoutBecauseUnauthorized(sessionContext, addToast, err) */
    throw err
  }
}

const ButtonFollow = styled.button`
  /* display: var(--display); */
  display: flex;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-align: center;
  width: var(--width);
  height: var(--height);
  opacity: 0.8;
  filter: drop-shadow(0px 2px 12px red);

  svg {
    /*     margin-right: 0.5rem;
    margin-bottom: 0.3rem;
    padding: 0.2rem; */
    fill: var(--fill);
    stroke: var(--stroke);
    width: var(--width);
    height: var(--height);
  }

  &:hover {
    svg {
      filter: drop-shadow(0px 4px 6px #ff974c);
    }
  }
`

const PageLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
`
const Left = styled.div`
  flex-basis: 30rem; // Where we do not provide a flex-basis value at all, the sidebar’s width is equal to the width of its contents
  flex-grow: 1;
  margin: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
`
const MyText = styled.p`
  font-size: var(--font-size);
  color: #cccccc;
  margin-bottom: var(--margin-bottom);
  text-shadow: var(--text-shadow);

  &:hover {
    text-shadow: var(--text-shadow-hover);
  }
`
const Row_Icons = styled.div`
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
  height: 5rem;
`

const Right = styled.div`
  flex-basis: 0;
  flex-grow: 999;
  /* min-width: 72%; */
  /* min-width: calc(50% - 1rem); */
  min-width: calc(70% - 1rem);
`

const BackgroundRadial = styled.div`
  background: radial-gradient(#555, #111);
  /*   padding-top: 2rem; */
  min-height: 110vh;
  position: relative;
`

const HelperToggle = styled.button`
  position: absolute;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  background-color: #cccccc;
  width: 26px;
  height: 26px;
  font-size: 22px;
  top: 10px;
  right: 5px;
  color: #000000;
`

const ButtonDelete = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: #cccccc;
  margin-left: 0.6rem;
  margin-top: 0.1rem;
  color: #000000;

  /* padding-top: 0.3rem; */
`

const WrapperNotifications = styled.ul`
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  top: 0;
  z-index: 300;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  list-style: none;
`
const Notification = styled(motion.div)`
  background-image: linear-gradient(
    -180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  border-radius: 10px;
  margin: 0.5rem;
`
const NotificationText = styled.p`
  color: #cccccc;
  padding: 1rem;
`

const ButtonExport = styled.div`
  width: 24px;
  height: 24px;

  /*  outline: none; */
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 50%;
  color: #cccccc;

  margin-right: 0.5rem;

  position: relative;

  :hover {
    svg {
      filter: drop-shadow(0px 0px 4px #ff974c);
    }
  }
`
