import React from "react"

export default (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 1280.000000 1280.000000"
    fill="none"
  >
    <path
      d="M1342 11457c-518-518-942-947-942-952 0-6 921-931 2048-2058l2047-2047-2047-2047C1321 3226 400 2301 400 2295c0-13 1882-1895 1895-1895 6 0 931 921 2058 2048l2047 2047 2047-2047c1127-1127 2052-2048 2058-2048 13 0 1895 1882 1895 1895 0 6-921 931-2048 2058L8305 6400l2047 2047c1127 1127 2048 2052 2048 2058 0 13-1882 1895-1895 1895-6 0-931-921-2058-2048L6400 8305l-2047 2047c-1127 1127-2052 2048-2058 2048s-434-424-953-943z"
      transform="matrix(.1 0 0 -.1 0 1280)"
    />
  </svg>
)
